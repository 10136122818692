import { defineMessages } from 'react-intl-next';
export const messages = defineMessages({
  createAnotherLabel: {
    id: 'proforma-jira-issue-forms.common.ui.footer.create-another-label',
    defaultMessage: 'Create another issue',
    description: 'The "create another" checkbox label for ProForma Issue Forms.'
  },
  createAnotherLabelIssueTermRefresh: {
    id: 'proforma-jira-issue-forms.common.ui.footer.create-another-label-issue-term-refresh',
    defaultMessage: 'Create another issue',
    description: 'The "create another" checkbox label for ProForma Issue Forms.'
  },
  createButtonLabel: {
    id: 'proforma-jira-issue-forms.common.ui.footer.create-button-label',
    defaultMessage: 'Create',
    description: 'The create button label for ProForma Issue Forms.'
  },
  failedCreatingFlagDescription: {
    id: 'proforma-jira-issue-forms.common.ui.footer.failed-creating-flag-description',
    defaultMessage: 'Please try again later.',
    description: 'Flag description displayed when the issue failed creating in ProForma Issue Forms due to an unknown error.'
  },
  failedCreatingWithSaveErrorsFlagDescription: {
    id: 'proforma-jira-issue-forms.common.ui.footer.failed-creating-with-save-errors-flag-description',
    defaultMessage: 'Resolve any invalid answers and check all required fields are complete.',
    description: 'Flag description displayed when the issue failed creating in ProForma Issue Forms due to invalid form answers.'
  },
  failedCreatingFlagTitle: {
    id: 'proforma-jira-issue-forms.common.ui.footer.failed-creating-flag-title',
    defaultMessage: 'We couldn’t create your issue.',
    description: 'Flag title displayed when the issue failed creating in ProForma Issue Forms.'
  },
  failedCreatingFlagTitleIssueTermRefresh: {
    id: 'proforma-jira-issue-forms.common.ui.footer.failed-creating-flag-title-issue-term-refresh',
    defaultMessage: 'We couldn’t create your issue.',
    description: 'Flag title displayed when the issue failed creating in ProForma Issue Forms.'
  },
  successfullyCreatedFlagCopyLinkAction: {
    id: 'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-copy-link-action',
    defaultMessage: 'Copy link',
    description: 'Flag copy link action displayed when the issue is successfully created in ProForma Issue Forms.'
  },
  successfullyCreatedFlagTitle: {
    id: 'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-title',
    defaultMessage: 'You’ve created "{issueKey}" issue.',
    description: 'Flag title displayed when the issue is successfully created in ProForma Issue Forms.'
  },
  successfullyCreatedFlagTitleIssueTermRefresh: {
    id: 'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-title-issue-term-refresh',
    defaultMessage: 'You’ve created "{issueKey}" issue.',
    description: 'Flag title displayed when the issue is successfully created in ProForma Issue Forms.'
  },
  successfullyCreatedFlagViewIssueAction: {
    id: 'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-view-issue-action',
    defaultMessage: 'View issue',
    description: 'Flag view issue action displayed when the issue is successfully created in ProForma Issue Forms.'
  },
  successfullyCreatedFlagViewIssueActionIssueTermRefresh: {
    id: 'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-view-issue-action-issue-term-refresh',
    defaultMessage: 'View issue',
    description: 'Flag view issue action displayed when the issue is successfully created in ProForma Issue Forms.'
  }
});